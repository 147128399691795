import axios from "axios";

export const checkUser = async (email) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const userType = await axios.post("https://admin.codingx.app/support/unsubPython", JSON.stringify({email}, getCircularReplacer()),
    { headers }
  );
  return userType.data.data.unsubscribe;
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};